import { http } from '@/plugins/axios'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { setCookie } from '@/utils/support'
const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    user: {}
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_USER_INFO: (state, userInfo) => {
      state.user = userInfo
    }
  },

  getters: {
    getUserInfo: state => state.user
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.account.trim()
      return new Promise((resolve, reject) => {
        http({
          url: '/manage/passport',
          method: 'POST',
          data: {
            account: username,
            password: userInfo.password
          }
        })
          .then(response => {
            const tokenStr = response.authorization
            setToken(tokenStr)
            commit('SET_TOKEN', tokenStr)
            commit('SET_USER_INFO',  response.login_info || {})
            localStorage.setItem('userName', username)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then(response => {
            const data = response.data
            if (data.roles && data.roles.length > 0) {
              // 验证返回的roles是否是一个非空数组
              commit('SET_ROLES', data.roles)
            } else {
              reject('getInfo: roles must be a non-null array !')
            }
            commit('SET_NAME', data.username)
            commit('SET_AVATAR', data.icon)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 登出
    LogOut({ commit }) {
      return new Promise((resolve, reject) => {
        http({
          url: '/manage/passport',
          method: 'delete'
        })
          .then(() => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            removeToken()
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    }
  }
}

export default user
