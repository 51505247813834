import Vue from 'vue'
import App from './App.vue'
import { router } from './router/index'
import store from '@/store/index'
import './plugins'
import registerGlobalComponents from './plugins/register-components'
import { VueJsonp } from 'vue-jsonp'
import dataV from '@jiaminghi/data-view'
import '@/flexible.js'
Vue.use(dataV)
Vue.use(VueJsonp)
registerGlobalComponents()
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store,
  beforeCreate() {
    Vue.prototype.$bus = this
  }
}).$mount('#app')
