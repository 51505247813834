<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
      collapsed: false,
       isRouterAlive: true
    }
  },

  computed: {},
  provide() {
    return {
      reload: this.reload
    }
  },
  components: {},

  methods: {
      reload() {
      this.isRouterAlive = false
      this.$nextTick(()  => {
         this.isRouterAlive = true
      })
    }
  }
}
</script>
<style lang="scss" scoped>
#app {
  width: 100%;
  // min-width: 1200px;
  // height: 100vh;
  // background: url('~@/assets/img/bg_mask.png') no-repeat;
  // background-size: cover;
}
</style>
