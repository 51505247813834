<template>
  <div class="container">
    <!-- <el-breadcrumb class="breadcrumb" separator="/ ">
      <el-breadcrumb-item v-for="item in matchedArr" :key="item.path" :to="item.path">
        {{ item.name }}
      </el-breadcrumb-item>
    </el-breadcrumb> -->
    <a-breadcrumb>
      <a-breadcrumb-item v-for="item in matchedArr" :key="item.path"> {{ item.name }}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumd',
  computed: {
    matchedArr() {
      let temp = [],
        temps = []
      this.$route.matched.filter((item, index, self) => {
        if (item.meta.name) {
          const name = item.meta.name
          temp.push({
            name: name,
            path: item.path
          })
        }
        // if (item.name) {
        //   const name = item.name;
        //   temp.push(name);
        // }
      })
      temp.filter((item, index, self) => {
        if (!temps.includes(item)) {
          temps.push(item)
        }
      })

      return temps
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  position: relative;
  // padding: 0 10px;
  color: #000;
  font-weight: bold;
  font-size: 18px;
  margin: 0;
}
// .container::before {
//   content: '';
//   position: absolute;
//   top: 50%;
//   left: 0px;
//   margin-top: -6px;
//   width: 4px;
//   height: 15px;
//   background-color: #00aeff;
// }
.ant-breadcrumb /deep/ {
  font-size: 16px;
  font-family: 'PingFang SC';
  font-style: normal;
}
</style>
