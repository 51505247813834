import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
// 解决重复点击路由报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export const routes = [
  {
    path: '/',
    redirect: '/map',
    meta: {
      hidden: true
    }
  },
  {
    path:'/map',
    name:'Map',
    component:()  => import('@/views/map'),
    meta:{hidden:true}
  },
  // {
  //   path: '/login',
  //   meta: {
  //     hidden: true
  //   },
  //   component: () => import('@/views/login/index')
  // },
  // {
  //   path: '/resetpassword',
  //   meta: {
  //     hidden: true
  //   },
  //   component: () => import('@/views/login/reset-password.vue')
  // },
  // {
  //   path: '/home',
  //   name: 'Home',
  //   meta: {
  //     icon: 'home',
  //     name: '首页'
  //   },
  //   component: () => import('../views/home/index.vue')
  // },
  // 智能厕所管理
  // {
  //   path: '/toiletManage',
  //   name: 'ToiletManage',
  //   redirect: '/toiletManage/list',
  //   component: () => import('@/views/toiletManage/index.vue'),
  //   meta: { name: '智能厕所管理' },
  //   children: [
  //     // {
  //     //   path: '/toiletManage/list',
  //     //   name: 'ToiletList',
  //     //   component: () => import('@/views/toiletManage/list.vue'),
  //     //   meta: { name: '智能厕所管理', hidden: true }
  //     // },
  //     {
  //       path: '/toiletManage/addEdit/:id?',
  //       name: 'ToiletAddEdit',
  //       component: () => import('@/views/toiletManage/addEdit.vue'),
  //       meta: { name: '新建及编辑', hidden: true }
  //     },
  //     {
  //       path: '/toiletManage/detail/:id?',
  //       name: 'ToiletDetail',
  //       component: () => import('@/views/toiletManage/detail.vue'),
  //       meta: { name: '厕所详情', hidden: true }
  //     },
  //     {
  //       path: '/toiletManage/upload',
  //       name: 'ToiletUpload',
  //       component: () => import('@/components/uploader/upload.vue'),
  //       meta: { name: '批量上传', hidden: true }
  //     },
  //     {
  //       path: '/toiletManage/uploadRecord',
  //       name: 'ToiletUploadRecord',
  //       component: () => import('@/components/uploader/upload-record.vue'),
  //       meta: { name: '上传历史', hidden: true }
  //     }
  //   ]
  // },
  // {
  //   path: '/toiletManage/list',
  //   name: 'ToiletList',
  //   component: () => import('@/views/toiletManage/list.vue'),
  //   meta: { name: '智能厕所管理', hidden: true }
  // },
  // // 车辆及驾驶员管理
  // {
  //   path: '/carAndDriverManage',
  //   name: 'CarAndDriverManage',
  //   redirect: '/carAndDriverManage/list',
  //   component: () => import('@/views/carAndDriverManage/index.vue'),
  //   meta: { name: '车辆及驾驶员管理' },
  //   children: [
  //     // {
  //     //   path: '/carAndDriverManage/list',
  //     //   name: 'CarAndDriverList',
  //     //   component: () => import('@/views/carAndDriverManage/list.vue'),
  //     //   meta: { name: '车辆及驾驶员管理', hidden: true }
  //     // },
  //     {
  //       path: '/carAndDriverManage/addEdit/:id?',
  //       name: 'CarAndDriverAddEdit',
  //       component: () => import('@/views/carAndDriverManage/addEdit.vue'),
  //       meta: { name: '新建及编辑', hidden: true }
  //     },
  //     {
  //       path: '/carAndDriverManage/detail/:id?',
  //       name: 'CarAndDriverDetail',
  //       component: () => import('@/views/carAndDriverManage/detail.vue'),
  //       meta: { name: '车辆详情', hidden: true }
  //     },
  //     {
  //       path: '/carAndDriverManage/upload',
  //       name: 'CarAndDriverUpload',
  //       component: () => import('@/components/uploader/upload.vue'),
  //       meta: { name: '批量上传', hidden: true }
  //     },
  //     {
  //       path: '/carAndDriverManage/uploadRecord',
  //       name: 'CarAndDriverUploadRecord',
  //       component: () => import('@/components/uploader/upload-record.vue'),
  //       meta: { name: '上传历史', hidden: true }
  //     }
  //   ]
  // },
  // {
  //   path: '/carAndDriverManage/list',
  //   name: 'CarAndDriverList',
  //   component: () => import('@/views/carAndDriverManage/list.vue'),
  //   meta: { name: '车辆及驾驶员管理', hidden: true }
  // },
  // //工单管理
  // {
  //   path: '/orderManage',
  //   name: 'OrderManage',
  //   redirect: '/orderManage/list',
  //   component: () => import('@/views/orderManage/index.vue'),
  //   meta: { name: '工单管理' },
  //   children: [
  //     // {
  //     //   path: '/orderManage/list',
  //     //   name: 'OrderList',
  //     //   component: () => import('@/views/orderManage/list.vue'),
  //     //   meta: { name: '工单管理', hidden: true }
  //     // },
  //     {
  //       path: '/orderManage/add',
  //       name: 'OrderAdd',
  //       component: () => import('@/views/orderManage/add.vue'),
  //       meta: { name: '新建', hidden: true }
  //     },
  //     {
  //       path: '/orderManage/detail/:id?',
  //       name: 'OrderDetail',
  //       component: () => import('@/views/orderManage/detail.vue'),
  //       meta: { name: '查看及编辑', hidden: true }
  //     },
  //     {
  //       path: '/orderManage/upload',
  //       name: 'OrderUpload',
  //       component: () => import('@/components/uploader/upload.vue'),
  //       meta: { name: '批量上传', hidden: true }
  //     },
  //     {
  //       path: '/orderManage/uploadRecord',
  //       name: 'OrderUploadRecord',
  //       component: () => import('@/components/uploader/upload-record.vue'),
  //       meta: { name: '上传历史', hidden: true }
  //     }
  //   ]
  // },
  // {
  //   path: '/orderManage/list',
  //   name: 'OrderList',
  //   component: () => import('@/views/orderManage/list.vue'),
  //   meta: { name: '工单管理', hidden: true }
  // },
  // //账号管理
  // {
  //   path: '/accountManage',
  //   name: 'AccountManage',
  //   redirect: '/accountManage/list',
  //   component: () => import('@/views/accountManage/index.vue'),
  //   meta: { name: '账号管理' },
  //   children: [
  //     // {
  //     //   path: '/accountManage/list',
  //     //   name: 'AccountList',
  //     //   component: () => import('@/views/accountManage/list.vue'),
  //     //   meta: { name: '账号管理', hidden: true }
  //     // },
  //     {
  //       path: '/accountManage/addEdit/:id?',
  //       name: 'AccountAddEdit',
  //       component: () => import('@/views/accountManage/addEdit.vue'),
  //       meta: { name: '新建及编辑', hidden: true }
  //     },
  //     {
  //       path: '/accountManage/detail/:id?',
  //       name: 'AccountDetail',
  //       component: () => import('@/views/accountManage/detail.vue'),
  //       meta: { name: '账号详情', hidden: true }
  //     }
  //   ]
  // },
  // {
  //   path: '/accountManage/list',
  //   name: 'AccountList',
  //   component: () => import('@/views/accountManage/list.vue'),
  //   meta: { name: '账号管理', hidden: true }
  // },
  // // 个人中心
  // {
  //   path: '/userManage',
  //   name: 'UserManage',
  //   component: () => import('@/views/userManage/detail.vue'),
  //   meta: { name: '个人中心', hidden: true }
  // }
]

export const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})
